<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Implantar Faturas" icon="pi pi-plus" class="p-button-success mr-2"
                                @click="ImplantarFaturas" />

                        </div>
                    </template>
                </Toolbar>

                <Dialog v-model:visible="ModalImplantar" :style="{ width: '600px' }" header="Implantar Faturas"
                    :modal="true" class="p-fluid">
                    <div class="field">
                        <label for="id_empresa">Empresa</label>
                        <Dropdown id="id_empresa" filter required="true" v-model="id_empresa" :options="tipos_empresas"
                            optionLabel="label" optionValue="value" placeholder="Selecione uma empresa"></Dropdown>
                    </div>

                    <div class="field">
                        <label for="mes_referencia">Fatura(s)</label>
                        <FileUpload mode="basic" ref="arquivo" v-on:change="onChangeFileUpload()"
                            chooseLabel="Selecionar Arquivo (PDF/ZIP)" name="arquivo" accept=".zip,.pdf"
                            :maxFileSize="2147483648" />
                    </div>

                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
                            @click="this.ModalImplantar = false" />
                        <Button label="Implantar" icon="pi pi-check" :loading="loading" class="p-button-success p-button-sm"
                            @click="implantar()" />
                    </template>
                </Dialog>

                <Dialog v-model:visible="ModalFatura" :style="{ width: '90%' }" header="Fatura" :modal="true"
                    class="p-fluid">
                    <div class="field">
                        <label for="id_empresa">Empresa</label>
                        <Dropdown id="id_empresa" filter required="true" v-model="filtros.id_empresa"
                            :options="tipos_empresas" optionLabel="label" optionValue="value"
                            placeholder="Selecione uma empresa"></Dropdown>
                    </div>
                    <div class="field">
                        <label for="unidade">Unidade</label>
                        <InputText id="unidade" v-model.trim="filtros.unidade" required="true" autofocus />

                    </div>
                    <div class="field">
                        <label for="mes_referencia">Mês Referencia</label>
                        <Calendar id="mes_referencia" view="month" dateFormat="mm/yy" v-model="filtros.mes_referencia"
                            required="true" autofocus />

                    </div>

                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
                            @click="this.ModalFatura = false" />
                        <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm"
                            @click="Salvar()" />
                    </template>
                </Dialog>




                <!-- Modal deletar varias faturas -->
                <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span v-if="fatura">Apagar Tipos de Concessionarias selecionados?</span>
                    </div>
                    <template #footer>
                        <Button label="Não" icon="pi pi-times" class="p-button-text"
                            @click="deleteProductsDialog = false" />
                        <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
                    </template>
                </Dialog>



            </div>
        </div>
    </div>
</template>

<script>

import { FilterMatchMode } from "primevue/api";
import funcoes from '../../funcoes.js';

export default {
    mixins: [funcoes],
    data() {
        return {
            arquivo: "",
            loading: false,
            filtros: [],
            ModalImplantar: false,
            faturas: [],
            fatura: {},
            selectImplantacoes: null,
            ModalFatura: false,

            medidor: {},
            modalEditMedidor: false,
            medidorDialog: false,

            itens_faturados: {},
            modalEditItens: false,

            dados_faturas: {},
            modalEditDados: false,

            dados_fiscais: {},
            modalEditDadosFiscais: false,

            dados_tributos: {},
            modalEditDadosTributos: false,

            deleteModalFatura: false,
            deleteMedidorDialog: false,
            deleteDadosDialog: false,
            deleteDadosFiscaisDialog: false,
            deleteDadosTributosDialog: false,
            deleteItensFaturadosDialog: false,
            deleteProductsDialog: false,

            filters: {},
            submitted: false,

            tipos_empresas: [],
            desc_tipos_empresas: [],
            tipo_insumo: [],
            desc_tipo_insumo: [],
            tipos_modalidades: [],
            tipos_concess: [],
            tipos_pagamentos: [],
            tipos_classe_tarifaria: [],
            tipos_subclasse: [],
            tipos_tensao: [],
            tipos_ligacao: [],
            id_empresa: "",

        };
    },

    created() {
        this.initFilters();
    },
    mounted() {
        // funçoes importadas do arquivo funcoes.js
        this.listarFaturas();
        //this.listarTipoInsumo();
        this.listarEmpresas();
        //this.listarModalidadesTarifarias();
        //this.listarTiposConcessionarias();
        //this.listarPagamento();
        //this.listarClasseTarifaria();
        //this.listarSubclasse();
        //this.listarTensao();
        //this.listarLigacao();



    },


    methods: {
        openNew() {
            this.fatura = {};
            this.submitted = false;
            this.ModalFatura = true;
        },

        onChangeFileUpload() {
            this.arquivo = this.$refs.arquivo.files[0];
        },

        ImplantarFaturas() {
            this.ModalImplantar = true;
        },

        editarFatura(fatura) {
            this.ModalFatura = true;
            this.fatura = { ...fatura };
        },


        implantar() {
            
            if (this.arquivo == "" || this.id_empresa == "") {
                this.$toast.add({
                    severity: "error",
                    summary: "Ops!",
                    detail: "Preencher todos os campo!",
                    life: 3000,
                });
                return false;
            }
            let formData = new FormData();
            formData.append("arquivo", this.arquivo);
            formData.append("id_cliente", this.id_empresa);
            this.loading = true;

            this.axios
                .post("/faturas/upload", formData)
                .then((response) => {

                    this.$toast.add({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Faturas inserida na fila processamento!\n\nId: "+response.data.fila,
                        life: 9000,
                    });
                    this.loading = false;
                    this.ModalImplantar = false;

                })
                .catch(() => {

                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });

                    this.loading = false;

                });
        },



        // criar/editar fatura
        salvarFatura() {
            this.submitted = true;

            if (this.fatura.id) {
                // Ediçao de fatura
                this.axios
                    .post("/faturas/editar", this.fatura)
                    .then(() => {

                        // console.log(this.fatura)

                        this.ModalFatura = false;
                        this.fatura = {};
                        this.listarFaturas();
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            } else {
                // Cadastrar nova fatura
                this.axios
                    .post("/faturas/adicionar", this.fatura)
                    .then(() => {

                        // alert(response.data.data)

                        this.ModalFatura = false;
                        this.fatura = {};
                        this.listarFaturas();
                    })
                    .catch(() => {
                        self.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            }
        },

        salvarMedidorFatura() {
            this.submitted = true;
            console.log('começa')
            console.log(this.medidor)
            console.log('termina')

            if (this.medidor.id) {
                // Ediçao de medidor
                console.log('entrou editar')
                this.axios
                    .post("/faturas/medidor/editar", this.medidor)
                    .then(() => {

                        // console.log(this.medidor)

                        this.modalEditMedidor = false;
                        this.medidor = {};
                        this.listarFaturas();
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            } else {
                // Cadastrar nova medidor
                console.log('entrou aqui')
                console.log(this.medidor)
                this.axios
                    .post("/faturas/medidor/adicionar", this.medidor)
                    .then(() => {

                        // alert(response.data.data)

                        this.modalEditMedidor = false;
                        this.medidor = {};
                        this.listarFaturas();
                    })
                    .catch(() => {
                        self.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            }
        },

        salvarItensFaturadosFatura() {
            this.submitted = true;

            if (this.itens_faturados.id) {
                // Ediçao de itens
                this.axios
                    .post("/faturas/itens-faturados/editar", this.itens_faturados)
                    .then(() => {

                        // console.log(this.itens_faturados)

                        this.modalEditItens = false;
                        this.itens_faturados = {};
                        this.listarFaturas();
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            } else {
                // Cadastrar nova itens_faturados
                this.axios
                    .post("/faturas/itens-faturados/adicionar", this.itens_faturados)
                    .then(() => {

                        // alert(response.data.data)

                        this.modalEditItens = false;
                        this.itens_faturados = {};
                        this.listarFaturas();
                    })
                    .catch(() => {
                        self.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            }
        },

        salvarDadosFatura() {
            this.submitted = true;

            if (this.dados_faturas.id) {
                // Ediçao de itens
                this.axios
                    .post("/faturas/dados/editar", this.dados_faturas)
                    .then(() => {

                        // console.log(this.dados_faturas)

                        this.modalEditDados = false;
                        this.dados_faturas = {};
                        this.listarFaturas();
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            } else {
                // Cadastrar nova dados_faturas
                this.axios
                    .post("/faturas/dados/adicionar", this.dados_faturas)
                    .then(() => {

                        // alert(response.data.data)

                        this.modalEditDados = false;
                        this.dados_faturas = {};
                        this.listarFaturas();
                    })
                    .catch(() => {
                        self.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            }
        },

        salvarDadosFiscaisFatura() {
            this.submitted = true;

            if (this.dados_fiscais.id) {
                // Ediçao de itens
                this.axios
                    .post("/faturas/dados-fiscais/editar", this.dados_fiscais)
                    .then(() => {

                        // console.log(this.dados_fiscais)

                        this.modalEditDados = false;
                        this.dados_fiscais = {};
                        this.listarFaturas();
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            } else {
                // Cadastrar nova dados_fiscais
                this.axios
                    .post("/faturas/dados-fiscais/adicionar", this.dados_fiscais)
                    .then(() => {

                        // alert(response.data.data)

                        this.modalEditDados = false;
                        this.dados_fiscais = {};
                        this.listarFaturas();
                    })
                    .catch(() => {
                        self.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            }
        },

        salvarDadosTributosFatura() {
            this.submitted = true;

            if (this.dados_tributos.id) {
                // Ediçao de itens
                this.axios
                    .post("/faturas/dados-tributos/editar", this.dados_tributos)
                    .then(() => {

                        // console.log(this.dados_tributos)

                        this.modalEditDadosTributos = false;
                        this.dados_tributos = {};
                        this.listarFaturas();
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            } else {
                // Cadastrar nova dados_tributos
                this.axios
                    .post("/faturas/dados-tributos/adicionar", this.dados_tributos)
                    .then(() => {

                        // alert(response.data.data)

                        this.modalEditDadosTributos = false;
                        this.dados_tributos = {};
                        this.listarFaturas();
                    })
                    .catch(() => {
                        self.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            }
        },







        apagarFaturasModal(fatura) {
            this.fatura = fatura;
            this.deleteModalFatura = true;
        },

        apagarDadosMedidorModal(medidor_faturas) {
            this.medidor_faturas = medidor_faturas;
            this.deleteMedidorDialog = true;
        },

        apagarDadosFiscaisModal(dados_fiscais) {
            this.dados_fiscais = dados_fiscais;
            this.deleteDadosFiscaisDialog = true;
        },

        apagarDadosTributosModal(dados_tributos) {
            this.dados_tributos = dados_tributos;
            this.deleteDadosTributosDialog = true;
        },

        apagarDadosFaturaModal(dados_faturas) {
            this.dados_faturas = dados_faturas;
            this.deleteDadosDialog = true;
        },

        apagarItensFaturadosModal(itens_faturados) {
            this.itens_faturados = itens_faturados;
            this.deleteItensFaturadosDialog = true;
        },

        // botao de excluir selecionados
        confirmDeleteSelected() {
            this.deleteProductsDialog = true;
        },

        apagarFaturas() {
            this.axios
                // console.log(this.concessionaria)
                .post("/faturas/apagar", this.fatura)
                .then(() => {

                    this.deleteModalFatura = false;
                    this.fatura = {};
                    this.$toast.add({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Fatura apagada com sucesso!",
                        life: 3000,
                    });

                    this.listarFaturas();


                })
                .catch(() => {

                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });

        },

        apagarItensFaturados() {
            this.axios
                // console.log(this.concessionaria)
                .post("/faturas/itens-faturados/apagar", this.itens_faturados)
                .then(() => {

                    this.deleteItensFaturadosDialog = false;
                    this.itens_faturados = {};
                    this.$toast.add({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Item apagado com sucesso!",
                        life: 3000,
                    });

                    this.listarFaturas();


                })
                .catch(() => {

                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });

        },

        apagarDadosMedidor() {
            this.axios
                // console.log(this.concessionaria)
                .post("/faturas/medidor/apagar", this.medidor_faturas)
                .then(() => {

                    this.deleteMedidorDialog = false;
                    this.medidor_faturas = {};
                    this.$toast.add({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Medidor apagado com sucesso!",
                        life: 3000,
                    });

                    this.listarFaturas();


                })
                .catch(() => {

                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });

        },

        apagarDados() {
            this.axios
                // console.log(this.concessionaria)
                .post("/faturas/dados/apagar", this.dados_faturas)
                .then(() => {

                    this.deleteDadosDialog = false;
                    this.dados_faturas = {};
                    this.$toast.add({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Medidor apagado com sucesso!",
                        life: 3000,
                    });

                    this.listarFaturas();


                })
                .catch(() => {

                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });

        },

        apagarDadosFiscais() {
            this.axios
                // console.log(this.concessionaria)
                .post("/faturas/dados-fiscais/apagar", this.dados_fiscais)
                .then(() => {

                    this.deleteDadosFiscaisDialog = false;
                    this.dados_fiscais = {};
                    this.$toast.add({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Dados Fiscais apagados com sucesso!",
                        life: 3000,
                    });

                    this.listarFaturas();


                })
                .catch(() => {

                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });

        },

        apagarDadosTributos() {
            this.axios
                // console.log(this.concessionaria)
                .post("/faturas/dados-tributos/apagar", this.dados_tributos)
                .then(() => {

                    this.deleteDadosTributosDialog = false;
                    this.dados_tributos = {};
                    this.$toast.add({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Dados Tributos apagados com sucesso!",
                        life: 3000,
                    });

                    this.listarFaturas();


                })
                .catch(() => {

                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });

        },

















        deleteSelectedProducts() {
            this.faturas = this.faturas.filter(
                (val) => !this.selectImplantacoes.includes(val)
            );

            let obj = this.selectImplantacoes;
            Object.keys(obj).forEach((key) => {
                this.axios
                    .post("/faturas/apagar", obj[key])
                    .then(() => {
                        this.deleteModalFatura = false;
                        this.fatura = {};
                        this.$toast.add({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "faturas apagadas com sucesso!",
                            life: 3000,
                        });
                        this.listarFaturas();
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            });

            this.deleteProductsDialog = false;
            this.selectImplantacoes = null;
        },

        exportCSV() {
            this.$refs.dt.exportCSV();
        },


        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },

    },
};


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>